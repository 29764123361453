<template>
  <div class="alipay">
    <wd-navbar title="" light>
      <div slot="left" @click="$goHome" style="display: flex; align-items: center">
        <wd-icon class="middle" name="arrow-left" />
        <span class="middle" style="font-size: 14px">返回</span>
      </div>
    </wd-navbar>
    <img src="../../assets/img/alipay.jpg" alt="">
  </div>
</template>

<script>
  export default {
    data(){
        return {
            orderId: null,
            roleId: null
        }
    },
    created() {
        this.orderId = this.$route.query.orderId
        this.roleId  = this.$route.query.roleId
        this.doPay()
    },
    methods: {
        doPay(){
          if( !this.$isWeiXin ) {
              let data = { roleId: this.roleId, orderId: this.orderId, formData: 1 }
              // 游览器直接调起支付宝支付
              this.$http.post("/payment/alipay/dopay", data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(res=>{
                  const div = document.createElement('div')
                  div.innerHTML = res
                  document.body.appendChild(div)
                  document.forms[0].submit()
              })
          }
        }
    }
  }
</script>

<style lang="less" scoped>
.alipay{
  width: 100vw;
  height: calc(100vh - 50px);
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
